// App.js

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { configureStore } from "./redux/store";
import HomePage from "./pages/HomePage";
import Dashboard from "./pages/Dashboard";
import "react-responsive-modal/styles.css";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import AuthenticatedRoute from "./components/Layouts/AuthenticatedRoute";

const { store, persistor } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Routes>
            <Route path="/dashboard" element={<AuthenticatedRoute component={Dashboard} />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </BrowserRouter>
      </PersistGate>
      <NotificationContainer />
    </Provider>
  );
}

export default App;
