import axios from "axios";
import * as actionTypes from "../actionTypes";

const  REACT_APP_API_ENDPOINT  = "http://api.database-services.com:8080";

export const setSystemInfoValue = (key, value) => {
  const data = {
    [key]: value,
  };
  return async (dispatch) => {
    dispatch(setSystemInfoValueSuccess(data));
  };
};

export const setSystemInfoValueSuccess = (data) => {
  return { type: actionTypes.SET_SYSTEM_INFO, data };
};

export const addSystemTag = () => {
  return async (dispatch) => {
    dispatch(addSystemTagSuccess());
  };
};

export const addSystemTagSuccess = () => {
  return { type: actionTypes.ADD_SYSTEM_TAG };
};

export const removeSystemTag = (index) => {
  return async (dispatch) => {
    dispatch(removeSystemTagSuccess(index));
  };
};

export const removeSystemTagSuccess = (index) => {
  return { type: actionTypes.REMOVE_SYSTEM_TAG, index };
};

export const handleTagChange = (type, value, index) => {
  return async (dispatch) => {
    dispatch(handleTagChangeSuccess(type, value, index));
  };
};

export const handleTagChangeSuccess = (type, value, index) => {
  console.log("amu");
  return { type: actionTypes.TAG_CHANGE_SUCCESS, tagType: type, value, index };
};

export const getSupportedSystem = () => {
  return async (dispatch) => {
    return axios
      .get(`${REACT_APP_API_ENDPOINT}/system/supported-system`)
      .then(async (result) => {
        await dispatch(getSupportedSystemSuccess(result.data?.data));
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(getSupportedSystemFailed(error));
        throw new Error(error);
      });
  };
};

export const getSupportedSystemSuccess = (data) => {
  return { type: actionTypes.GET_SUPPORTED_SYSTEM_SUCCESS, data };
};

export const getSupportedSystemFailed = (error) => {
  return { type: actionTypes.GET_SUPPORTED_SYSTEM_FAILED, error };
};

export const getCategories = (ssName) => {
  return async (dispatch) => {
    return axios
      .get(`${REACT_APP_API_ENDPOINT}/system/categories?ssName=${ssName}`)
      .then(async (result) => {
        await dispatch(getCategoriesSuccess(result.data?.data));
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(getCategoriesFailed(error));
        throw new Error(error);
      });
  };
};

export const getCategoriesSuccess = (data) => {
  return { type: actionTypes.GET_CATEGORIES_SUCCESS, data };
};

export const getCategoriesFailed = (error) => {
  return { type: actionTypes.GET_CATEGORIES_FAILED, error };
};

export const getSubCategories = (categories, ssName) => {
  return async (dispatch) => {
    return axios
      .get(
        `${REACT_APP_API_ENDPOINT}/system/sub-categories?categories=${categories.join(
          ","
        )}&ssName=${ssName}`
      )
      .then(async (result) => {
        await dispatch(getSubCategoriesSuccess(result.data?.data));
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(getSubCategoriesFailed(error));
        throw new Error(error);
      });
  };
};

export const getSubCategoriesSuccess = (data) => {
  return { type: actionTypes.GET_SUBCATEGORIES_SUCCESS, data };
};

export const getSubCategoriesFailed = (error) => {
  return { type: actionTypes.GET_SUBCATEGORIES_FAILED, error };
};

export const setSelectedCategory = (category) => {
  return async (dispatch) => {
    dispatch(setSelectedCategorySuccess(category));
  };
};

export const setSelectedCategorySuccess = (selectedCategpry) => {
  return { type: actionTypes.SET_SELECTED_CATEGORY, selectedCategpry };
};

export const setSelectedSubCategory = (subcategory) => {
  return async (dispatch) => {
    dispatch(setSelectedSubCategorySuccess(subcategory));
  };
};

export const setSelectedSubCategorySuccess = (subcategory) => {
  return { type: actionTypes.SET_SELECTED_SUBCATEGORY, subcategory };
};

export const setCategoryType = (type) => {
  return async (dispatch) => {
    dispatch(setCategoryTypeSuccess(type));
  };
};

export const setCategoryTypeSuccess = (value) => {
  return { type: actionTypes.SET_CATEGORY_TYPE, value };
};

export const setSelectedInputCategory = (category) => {
  return async (dispatch) => {
    dispatch(setSelectedInputCategorySuccess(category));
  };
};

export const setSelectedInputCategorySuccess = (selectedCategpry) => {
  return { type: actionTypes.SET_SELECTED_INPUT_CATEGORY, selectedCategpry };
};

export const setSubCategoryType = (type) => {
  return async (dispatch) => {
    dispatch(setSubCategoryTypeSuccess(type));
  };
};

export const setSubCategoryTypeSuccess = (value) => {
  return { type: actionTypes.SET_SUBCATEGORY_TYPE, value };
};

export const setSelectedInputSubCategory = (subCategory) => {
  return async (dispatch) => {
    dispatch(setSelectedInputSubCategorySuccess(subCategory));
  };
};

export const setSelectedInputSubCategorySuccess = (subCategory) => {
  return { type: actionTypes.SET_SELECTED_INPUT_SUBCATEGORY, subCategory };
};

export const getFilesByCategory = (categories , ssName) => {
  const selectedCategories = categories.map(category => category.value)
  console.log("selectedCategories",selectedCategories)
  return async (dispatch) => {
    return axios
      .get(
        `${REACT_APP_API_ENDPOINT}/system/upload-files?categories=${selectedCategories.join(",")}&ssName=${ssName}`
      )
      .then(async (result) => {
        await dispatch(getFilesByCategorySuccess(result.data?.data));
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(getFilesByCategoryFailed(error));
        throw new Error(error);
      });
  };
};

export const getFilesByCategorySuccess = (data) => {
  return { type: actionTypes.GET_FILES_BY_CATEGORIES_SUCCESS, data };
};

export const getFilesByCategoryFailed = (error) => {
  return { type: actionTypes.GET_FILES_BY_CATEGORIES_FAILED, error };
};

export const uploadFiles = (data, filePath) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    "file-path": filePath,
  };
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_API_ENDPOINT}/system/upload-file`, data, {
        headers: headers,
      })
      .then(async (result) => {
        await dispatch(uploadFilesSuccess(result.data?.fileUrls));
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(uploadFilesFailed(error));
        throw new Error(error);
      });
  };
};

export const uploadFilesSuccess = (data) => {
  return { type: actionTypes.UPLOAD_FILES_SUCCESS, data };
};

export const uploadFilesFailed = (error) => {
  return { type: actionTypes.UPLOAD_FILES_FAILED, error };
};

export const resetSystemInfo = () => {
  return { type: actionTypes.RESET_SYSTEM_INFO };
};