import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  getSubCategories,
  getSubCategoriesSuccess,
  setCategoryType,
  setSelectedCategory,
  setSelectedSubCategory,
  setSystemInfoValue,
} from "../redux/actions/userSystemActions";


const CategoryInfo = ({ handleNextStep, handleBackStep }) => {
  const dispatch = useDispatch();

  const categoriesList = useSelector(
    (state) => state.userSystem.categoriesList
  );
  const selectedCategory = useSelector(
    (state) => state.userSystem.selectedCategory
  );
  const selectedSubcategory = useSelector(
    (state) => state.userSystem.selectedSubcategory
  );
  const systemType = useSelector((state) => state.userSystem.systemType);

  const subCategoriesList = useSelector(
    (state) => state.userSystem.subCategoriesList
  );
  const selectedInputCategory = useSelector(
    (state) => state.userSystem.selectedInputCategory
  );
  const categoryType = useSelector((state) => state.userSystem.categoryType);
  const selectedInputSubCategory = useSelector(
    (state) => state.userSystem.selectedInputSubCategory
  );
  const subCategoryType = useSelector(
    (state) => state.userSystem.subCategoryType
  );
  const customAnalysis = useSelector(
    (state) => state.userSystem.customAnalysis
  );

  const getGroupedOptions = () => {
    if (subCategoriesList && subCategoriesList.length > 0) {
      const filteredData = subCategoriesList.filter(
        (item) => item.categoryDisplayName
      );

      const groupedData = filteredData.reduce((acc, item) => {
        const categoryDisplayName = item.categoryDisplayName;
        if (!acc[categoryDisplayName]) {
          acc[categoryDisplayName] = [];
        }
        acc[categoryDisplayName].push({
          label: item.displayName,
          value: item.name,
        });
        return acc;
      }, {});

      const result = Object.entries(groupedData).map(([label, options]) => ({
        label,
        options,
      }));
      return result;
    }
    return [];
  };

  useEffect(() => {
    if (selectedCategory.length > 0) {
      const categories = selectedCategory.map((item) => item.value);
      dispatch(getSubCategories(categories, systemType.value));
    } else {
      dispatch(getSubCategoriesSuccess([]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory.length , dispatch]);

  const groupedOptions = getGroupedOptions();

  const categoriesOptions =
    categoriesList && categoriesList.length > 0
      ? categoriesList.map((option) => {
          return {
            label: option.displayName,
            value: option.name,
          };
        })
      : [];

  const isDisabled =
    (categoryType === "select" && selectedCategory.length === 0) ||
    (categoryType === "input" && selectedInputCategory.length === 0) ||
    (subCategoryType === "select" && selectedSubcategory.length === 0) ||
    (subCategoryType === "input" && selectedInputSubCategory.length === 0);

  console.log("groupedOptions", groupedOptions);

  return (
    <div className="mx-4 my-24">
      <div class="mb-2 w-full">
        <div className="flex flex-row mb-8">
          <label
            for="category"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-16"
          >
            Select Category :
          </label>
          <div className="w-1/3">
            <Select
              onChange={(value) => {
                dispatch(setCategoryType("select"));
                dispatch(setSelectedCategory(value));
              }}
              value={categoryType === "select" ? selectedCategory : []}
              options={categoriesOptions}
              isMulti
            />
          </div>
        </div>
      </div>
      <div class="mb-2 w-full">
        <div className="flex flex-row mb-8">
          <label
            for="subcategory"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-9"
          >
            Select Sub Category :
          </label>
          <div className="w-1/3">
            <Select
              onChange={(value) => {
                dispatch(setSelectedSubCategory(value));
              }}
              value={categoryType === "select" ? selectedSubcategory : []}
              options={groupedOptions}
              isMulti
            />
          </div>
        </div>
      </div>

      <div class="mb-2 w-full">
        <div className="flex flex-row mb-8">
          <label
            for="subcategory"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-14"
          >
            Custom Analysis :
          </label>
          <div className="w-1/3">
            <textarea
              id="message"
              rows="4"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={(e) => dispatch(setSystemInfoValue('customAnalysis', e.target.value))}
              value={customAnalysis}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <button
          class={`border border-blue-700 text-blue-700 font-bold py-2 px-4 rounded h-12 w-24 mr-6`}
          onClick={handleBackStep}
        >
          Back
        </button>
        <button
          class={`bg-blue-700 text-white font-bold py-2 px-4 rounded h-12 w-24 float-right ${
            isDisabled && "opacity-50 cursor-not-allowed bg-blue-500"
          }`}
          onClick={handleNextStep}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CategoryInfo;
