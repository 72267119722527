import { GoogleLogin } from "@react-oauth/google";
import React, {  useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import analyticsLogo from "../assets/analytics-logo.png";
import InputField from "../components/InputField";
import { googleLogin, loginUser } from "../redux/actions/authActions";
import jwtDecode from "jwt-decode";

const Login = ({ signupNavigationHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginHandler = async () => {
    const data = {
      email,
      password,
    };

    try {
      await dispatch(loginUser(data));
      navigate("/dashboard");
      NotificationManager.success("Welcome to Data Analytics!");
    } catch (error) {
      NotificationManager.error(error.message);
    }
  };

  const googleLoginHandler = async(userInfo) => {
    const {name , email} = userInfo;

    const randomPassword = Math.random().toString(36).slice(-8);

    const data = {
      email,
      password : randomPassword,
      fullName : name
    };

    try {
      await dispatch(googleLogin(data));
      navigate("/dashboard");
      NotificationManager.success("Welcome to Data Analytics!");
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }


  return (
    <section class="bg-gray-50 w-full">
      <div class="flex flex-col mt-8 px-6 mx-auto lg:py-0">
        <div class="flex flex-row justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            src={analyticsLogo}
            class="mr-3 h-6 sm:h-9"
            alt="Flowbite Logo"
          />
          <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Data Anaytics
          </span>
        </div>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Sign in to your account
            </h1>
            <div class="space-y-4 md:space-y-6" action="#">
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email
                </label>
                <InputField
                  type="email"
                  name="email"
                  required
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <InputField
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>

              <button
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={loginHandler}
              >
                Sign in
              </button>
              <GoogleLogin
                onSuccess={({ credential }) => {
                  const userInfo = jwtDecode(credential);
                  googleLoginHandler(userInfo)
                }}
                onError={() => {
                  console.log("Login Failed");
                }}
                width="380"
              />
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Don’t have an account yet?{" "}
                <span
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500 cursor-pointer"
                  onClick={signupNavigationHandler}
                >
                  Sign up
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
