import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import InputField from "../components/InputField";
import { resetSystemInfo, uploadFiles } from "../redux/actions/userSystemActions";
import { getCurrentDateTime } from "../utils";

const Review = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const systemType = useSelector((state) => state.userSystem.systemType);
  const systemName = useSelector((state) => state.userSystem.systemName);
  const systemEnv = useSelector((state) => state.userSystem.systemEnv);
  const systemTags = useSelector((state) => state.userSystem.systemTags);
  const categoryType = useSelector((state) => state.userSystem.categoryType);
  const selectedCategory = useSelector(
    (state) => state.userSystem.selectedCategory
  );
  const selectedInputCategory = useSelector(
    (state) => state.userSystem.selectedInputCategory
  );
  const subCategoryType = useSelector(
    (state) => state.userSystem.subCategoryType
  );
  const selectedSubcategory = useSelector(
    (state) => state.userSystem.selectedSubcategory
  );
  const selectedInputSubCategory = useSelector(
    (state) => state.userSystem.selectedInputSubCategory
  );
  const uploadedFileUrls = useSelector(
    (state) => state.userSystem.uploadedFileUrls
  );
  const categoryFiles = useSelector((state) => state.userSystem.categoryFiles);
  const customAnalysis = useSelector(
    (state) => state.userSystem.customAnalysis
  );
  const currentUser = useSelector((state) => state.auth.currentUser);

  const email =
    currentUser && Object.keys(currentUser).length > 0
      ? currentUser.user.email
      : "";

  const userId =
    currentUser && Object.keys(currentUser).length > 0
      ? currentUser.user.id
      : 1;
  const [currentEmail, setEmail] = useState(email);

  const categories =
    categoryType === "select" ? selectedCategory : selectedInputCategory;

  const subCategories =
    subCategoryType === "select"
      ? selectedSubcategory
      : selectedInputSubCategory;

  const finishSubmissioHandler = async () => {
    let tags = [];
    const currentTime = getCurrentDateTime();

    if (systemTags && systemTags.length > 0) {
      tags = systemTags.map((tag) => {
        return `${tag.key} - ${tag.value}`;
      });
    }
    const data = {
      "system-name": systemName,
      "system-type": systemType.label,
      "system-env": systemEnv.label,
      "system-tags": tags,
      categories: categories.map((category) => category.label),
      "sub-categories": subCategories.map((subCategory) => subCategory.label),
      "file-urls": uploadedFileUrls,
      "custom-analysis": customAnalysis,
      email: currentEmail,
    };
    const jsonContent = JSON.stringify(data);

    const blob = new Blob([jsonContent], { type: "application/json" });

    const formData = new FormData();
    formData.append("inputFiles", blob, "data.json");

    const filePath = `${userId}/${systemType.value}/${systemName}/${currentTime}/`;

    await dispatch(uploadFiles(formData, filePath));
    await dispatch(resetSystemInfo())
    navigate("/")
  };
  return (
    <>

      <div
        class="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3 my-8"
        role="alert"
      >
        <svg
          class="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
        </svg>
        <p>You will receive the analysed data in 4 hrs through your email</p>
      </div>
      <div className="grid grid-cols-6 mt-12 w-full mb-4">
        <label class="block col-span-1  text-gray-900 flex items-center mr-10 text-lg">
          System type :
        </label>
        <div className="w-1/3 text-lg col-span-5	">{systemType.label}</div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          System name :
        </label>
        <div className="w-1/3 text-lg col-span-5">{systemName}</div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          System env :
        </label>
        <div className="w-1/3 text-lg col-span-5">{systemEnv.label}</div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          System Tags :
        </label>
        <div className="w-full text-lg col-span-5 flex flex-row flex-wrap">
          {systemTags.map((tag) => (
            <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-white  py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-black border border-black mr-2">
              <div class="mt-px">
                {tag.key} : {tag.value}
              </div>
            </div>
          ))}
        </div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          Categories :
        </label>
        <div className="w-full text-lg col-span-5 flex flex-row flex-wrap">
          {categories.map((category) => (
            <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-white  py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-black border border-black mr-2">
            <div class="mt-px">{category.label}</div>
            </div>
          ))}
        </div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          Sub Categories :
        </label>
        <div className="w-full text-lg col-span-5 flex flex-row flex-wrap">
          {subCategories.map((subCategory) => (
            <div class="center relative inline-block select-none whitespace-nowrap rounded-lg bg-white  py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-black border border-black mr-2">
            <div class="mt-px">{subCategory.label}</div>
            </div>
          ))}
        </div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          Custom Analysis :
        </label>
        <div className="w-1/3 text-lg col-span-5">{customAnalysis}</div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          Uploaded Files :
        </label>
        <div className="w-full text-lg col-span-5">
          {uploadedFileUrls.map((file, index) => (
            <div>
              <a
                href={file}
                download={file}
                target="_blank"
  rel="noopener noreferrer"

                className="text-blue-700 font-bold"
              >
                {" "}
                {categoryFiles[index]["displayName"]}.
                {categoryFiles[index]["fileType"]}
              </a>
            </div>
          ))}
        </div>{" "}
      </div>
      <div className="grid grid-cols-6 mb-4">
        <label class="block col-span-1 text-gray-900 flex items-center mr-10 text-lg">
          Email :
        </label>
        <InputField
          type="email"
          value={currentEmail}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </div>
      <div className="flex flex-row justify-end">
        <button
          onClick={finishSubmissioHandler}
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          Finish
        </button>
      </div>
    </>
  );
};

export default Review;
