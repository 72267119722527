import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Modal } from "react-responsive-modal";
import { AiOutlineMenu } from "react-icons/ai";
import analyticsLogo from "../assets/analytics-logo.png";
import Login from "../pages/Login";
import Register from "../pages/Register";
import { useNavigate } from "react-router-dom";

const modalStyles = {
  // Apply  custom width class to the modal content
  modalContainer: {
    width: "900px !important",
  },
};

const Header = ({ currentUser }) => {
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);

  const closeLoginModal = () => {
    setLoginModal(false);
  };
  const closeRegisterModal = () => {
    setRegisterModal(false);
  };

  const isAuthenticated =
    currentUser && currentUser.user && Object.keys(currentUser.user).length > 0;

  return (
    <>
      <header>
        <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
          <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <a href="/" class="flex items-center">
              <img
                src={analyticsLogo}
                class="mr-3 h-6 sm:h-9"
                alt="Flowbite Logo"
              />
              <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                Data Anaytics
              </span>
            </a>
            <div class="flex items-center lg:order-2">
              {isAuthenticated ? (
                <div
                  class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 cursor-pointer"
                  onClick={() => {
                    navigate("/dashboard");
                  }}
                >
                  Go to Dashboard
                </div>
              ) : (
                <>
                  <div
                    class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800 cursor-pointer"
                    onClick={() => {
                      setLoginModal(true);
                    }}
                  >
                    Log in
                  </div>
                  <div
                    class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800 cursor-pointer"
                    onClick={() => {
                      setRegisterModal(true);
                    }}
                  >
                    Get started
                  </div>
                </>
              )}
              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                aria-controls="mobile-menu-2"
                aria-expanded="false"
              >
                <span class="sr-only">Open main menu</span>
                <AiOutlineMenu className="text-2xl" />
              </button>
            </div>
            <div
              class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
              id="mobile-menu-2"
            >
              <Scrollspy
                items={[
                  "home",
                  "about-us",
                  "features",
                  "testimonials",
                  "contact-us",
                ]}
                currentClassName="is-current"
                className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0"
              >
                <li>
                  <a
                    href="#home"
                    class="block py-2 pr-4 pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                    aria-current="page"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#about-us"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    href="#features"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Features
                  </a>
                </li>
                <li>
                  <a
                    href="#testimonials"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <a
                    href="#contact-us"
                    class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                  >
                    Contact Us
                  </a>
                </li>
              </Scrollspy>
            </div>
          </div>
        </nav>
      </header>
      <Modal
        open={loginModal}
        onClose={closeLoginModal}
        styles={modalStyles}
        center
      >
        <Login
          signupNavigationHandler={() => {
            setLoginModal(false);
            setRegisterModal(true);
          }}
        />
      </Modal>
      <Modal open={registerModal} onClose={closeRegisterModal} center>
        <Register
          signinNavigationHandler={() => {
            setLoginModal(true);
            setRegisterModal(false);
          }}
        />
      </Modal>
    </>
  );
};

export default Header;
