import * as types from "../actionTypes";
import { produce } from 'immer';

const intialState = {
  loading: false,
  currentUser: {},
};

export default function authReducer(state = { ...intialState }, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.res;
      });
    case types.LOGIN_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = action.currentUser;
      });
    case types.LOGIN_USER_FAILED:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.REGISTER_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = action.currentUser;
      });
    case types.REGISTER_USER_FAILED:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.LOGOUT_USER_SUCCESS:
      console.log("test")
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    default:
      return state;
  }
}
