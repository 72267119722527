import React from "react";

const InputField = ({ className, disabled, ...restProps }) => {
  return (
    <input
      className={`${
        disabled
          ? "bg-gray-200 text-gray-500 border border-gray-300 py-2 px-4 rounded-md cursor-not-allowed"
          : `bg-white border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  ${className}`
      }`}
      disabled={disabled}
      {...restProps}
    />
  );
};

export default InputField;
