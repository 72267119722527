import React from "react";
import Header from "../components/Header";
import AboutUs from "../components/About-us";
import Footer from "../components/Footer";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import { useSelector } from "react-redux";

const HomePage = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);

  return (
    <>
      <Header currentUser={currentUser} />
      <AboutUs />
      <Features />
      <Testimonials />
      <Contact />
      <Footer />
    </>
  );
};

export default HomePage;
