const steps = [
  "System Info",
  "Select Analysis Category",
  "Upload Files",
  "Review",
];

const defaultSystemTags = [
  {
    key: "",
    value: "",
  },
];

const systemEnvOptions = [
  {
    label: "Development",
    value: "dev",
  },
  {
    label: "QA",
    value: "qa",
  },
  {
    label: "Prod",
    value: "prod",
  },
];

const getCurrentDateTime = () => {
  const now = new Date();

  const month = String(now.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-based
  const day = String(now.getDate()).padStart(2, "0");
  const year = now.getFullYear();
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");

  const formattedDate = `${month}-${day}-${year}-${hours}-${minutes}-${seconds}`;
  return formattedDate;
};
export { steps, defaultSystemTags, systemEnvOptions, getCurrentDateTime };
