import React from "react";

const Testimonials = () => (
  <section id="testimonials" class="bg-white dark:bg-gray-900">
    <div class="py-2 px-4 mx-auto max-w-screen-xl text-center lg:py-4 lg:px-6">
      <div class="mx-auto max-w-screen-sm">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Testimonials
        </h2>
      </div>
      <div class="grid mb-8 lg:mb-12 lg:grid-cols-2">
        <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
          <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              Speechless with the flow
            </h3>
            <p class="my-4">
              "I can't thank Data Analytics enough for their incredible database
              analysis tool. As a marketing manager, data plays a crucial role
              in our decision-making processes. With their free data analysis
              service, I was able to gain valuable insights into our customer
              behavior and campaign performance without any cost. The reports
              were clear, concise, and actionable, helping us optimize our
              marketing strategies. It's rare to find such a powerful tool
              that's also free. We're now considering their upgrade options to
              dive even deeper into our data. Highly recommended!"
            </p>
          </blockquote>
          <figcaption class="flex justify-center items-center space-x-3">
            <div class="space-y-0.5 font-medium dark:text-white text-left">
              <div>Bonnie Green</div>
              <div class="text-sm font-light text-gray-500 dark:text-gray-400">
                Lead Developer
              </div>
            </div>
          </figcaption>
        </figure>
        <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
          <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              Solid foundation for any project
            </h3>
            <p class="my-4">
              As a small business owner, I wear many hats, and one of them is
              data analysis. Data Analytics's free data analysis tool has been a
              game-changer for me. I was able to upload my customer data and
              receive insights that I didn't even know were possible. It helped
              me identify customer trends, streamline my marketing efforts, and
              improve our customer service. The fact that it's free is
              incredible. It's like having a data scientist in your pocket. I
              can't thank Data Analytics's enough for making data analysis
              accessible to businesses like mine."
            </p>
          </blockquote>
          <figcaption class="flex justify-center items-center space-x-3">
            <div class="space-y-0.5 font-medium dark:text-white text-left">
              <div>Roberta Casas</div>
              <div class="text-sm font-light text-gray-500 dark:text-gray-400">
                Lead Data Analyst
              </div>
            </div>
          </figcaption>
        </figure>
        <figure class="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 lg:border-b-0 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
          <blockquote class="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
            <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
              Mindblowing workflow and variants
            </h3>
            <p class="my-4">
              As a professional data analyst, I'm always on the lookout for
              tools that can enhance my work. Data Analytics's database analysis
              platform exceeded my expectations. It's not just about the free
              analysis; it's the quality and depth of insights it provides. The
              ability to customize analysis parameters is a game-changer for me.
              I've recommended it to my colleagues, and we're considering
              upgrading to access more advanced features.
            </p>
            <p class="my-4">
              Aesthetically, the well designed components are beautiful and will
              undoubtedly level up your next application."
            </p>
          </blockquote>
          <figcaption class="flex justify-center items-center space-x-3">
            <div class="space-y-0.5 font-medium dark:text-white text-left">
              <div>Jese Leos</div>
              <div class="text-sm font-light text-gray-500 dark:text-gray-400">
               Researcher
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  </section>
);

export default Testimonials;
