// AuthenticatedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";

function AuthenticatedRoute({ component: SubComp, ...rest }) {
  const currentUser = localStorage.getItem("auth");

  return currentUser ? <SubComp {...rest} /> : <Navigate to="/" replace />;
}

export default AuthenticatedRoute;
