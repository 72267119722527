import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import analyticsLogo from "../assets/analytics-logo.png";
import { getCurrentDateTime, steps } from "../utils";
import SystemInfo from "./SystemInfo";
import UploadFile from "./UploadFile";
import Review from "./Review";
import { logoutUser } from "../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import { googleLogout } from "@react-oauth/google";
import CategoryInfo from "./CategoryInfo";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilesByCategory,
  uploadFiles,
} from "../redux/actions/userSystemActions";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const selectedCategory = useSelector(
    (state) => state.userSystem.selectedCategory
  );
  const systemName = useSelector((state) => state.userSystem.systemName);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const systemType = useSelector((state) => state.userSystem.systemType);

  const userId =
    currentUser && Object.keys(currentUser).length > 0
      ? currentUser.user.id
      : 1;

  console.log("systemType>>", systemType);

  console.log("userId>>", userId);
  const logoutHandler = async () => {
    await dispatch(logoutUser());
    googleLogout();
    navigate("/");
  };

  const handleBackCategoryStep = () => {
    setActiveStep(0);
  };

  const uploadFileHandler = async (files) => {
    setLoading(true);
    const formData = new FormData();
    const currentTime = getCurrentDateTime()

    const filePath = `${userId}/${systemType.value}/${systemName}/${currentTime}/`;
    for (const file of files) {
      formData.append("inputFiles", file);
    }

    await dispatch(uploadFiles(formData, filePath));
    setLoading(false);
    setActiveStep(3);
  };
  return (
    <div className="mx-8">
      <div className="w-full my-8 flex flex-row justify-between">
        <a href="/" class="flex items-center">
          <img
            src={analyticsLogo}
            class="mr-3 h-6 sm:h-9"
            alt="Flowbite Logo"
          />
          <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Data Anaytics
          </span>
        </a>
        <button
          onClick={logoutHandler}
          class="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
        >
          Logout
        </button>
      </div>
      <div className="w-full mt-12 border px-6 pt-12 shadow-md">
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 ? (
          <SystemInfo handleNextStep={() => setActiveStep(1)} />
        ) : activeStep === 1 ? (
          <CategoryInfo
            handleNextStep={() => {
              setActiveStep(2);
              dispatch(getFilesByCategory(selectedCategory , systemType.value));
            }}
            handleBackStep={handleBackCategoryStep}
          />
        ) : activeStep === 2 ? (
          <UploadFile
            handleUpload={uploadFileHandler}
            loading={loading}
            handleBackStep={() => {
              setActiveStep(1);
            }}
          />
        ) : (
          <Review />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
