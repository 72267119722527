import { combineReducers } from 'redux';
import auth from './authReducer';
import userSystem from './userSystemReducer';

const rootReducer =()=> combineReducers({
  auth:auth,
  userSystem: userSystem
});

export default rootReducer;

