export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const SET_LOADING = 'SET_LOADING';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_SYSTEM_INFO = 'SET_SYSTEM_INFO';
export const ADD_SYSTEM_TAG = 'ADD_SYSTEM_TAG';
export const REMOVE_SYSTEM_TAG = 'REMOVE_SYSTEM_TAG';
export const TAG_CHANGE_SUCCESS = 'TAG_CHANGE_SUCCESS';
export const GET_SUPPORTED_SYSTEM_SUCCESS = 'GET_SUPPORTED_SYSTEM_SUCCESS';
export const GET_SUPPORTED_SYSTEM_FAILED = 'GET_SUPPORTED_SYSTEM_FAILED';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';
export const GET_SUBCATEGORIES_SUCCESS = 'GET_SUBCATEGORIES_SUCCESS';
export const GET_SUBCATEGORIES_FAILED = 'GET_SUBCATEGORIES_FAILED';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_SELECTED_SUBCATEGORY = 'SET_SELECTED_SUBCATEGORY';
export const SET_CATEGORY_TYPE = 'SET_CATEGORY_TYPE';
export const SET_SELECTED_INPUT_CATEGORY= 'SET_SELECTED_INPUT_CATEGORY'
export const SET_SUBCATEGORY_TYPE = 'SET_SUBCATEGORY_TYPE';
export const SET_SELECTED_INPUT_SUBCATEGORY= 'SET_SELECTED_INPUT_SUBCATEGORY';
export const GET_FILES_BY_CATEGORIES_SUCCESS = 'GET_FILES_BY_CATEGORIES_SUCCESS';
export const GET_FILES_BY_CATEGORIES_FAILED = 'GET_FILES_BY_CATEGORIES_FAILED';
export const UPLOAD_FILES_SUCCESS = 'UPLOAD_FILES_SUCCESS';
export const UPLOAD_FILES_FAILED = 'UPLOAD_FILES_FAILED';
export const RESET_SYSTEM_INFO = 'RESET_SYSTEM_INFO'
