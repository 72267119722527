import * as types from "../actionTypes";
import { produce } from "immer";
import { defaultSystemTags } from "../../utils";

const intialState = {
  systemTags: defaultSystemTags,
  systemName: "",
  systemType: "",
  systemEnv: "",
  supportedSystemList: [],
  categoriesList: [],
  subCategoriesList: [],
  selectedCategory: [],
  selectedSubcategory: [],
  categoryType: "select",
  selectedInputCategory: [],
  subCategoryType: "select",
  selectedInputSubCategory: [],
  categoryFiles: [],
  uploadedFileUrls: [],
  customAnalysis: "",
};

export default function userSystemReducer(state = { ...intialState }, action) {
  switch (action.type) {
    case types.SET_SYSTEM_INFO:
      return produce(state, (draft) => {
        const key =
          Object.keys(action.data) &&
          Object.keys(action.data).length > 0 &&
          Object.keys(action.data)[0];
        draft[key] = action["data"][key];
      });

    case types.ADD_SYSTEM_TAG:
      return produce(state, (draft) => {
        draft.systemTags = [...state.systemTags, ...defaultSystemTags];
      });

    case types.REMOVE_SYSTEM_TAG:
      return produce(state, (draft) => {
        const newSystemTags = state.systemTags.filter(
          (tag, index) => index !== action.index
        );
        draft.systemTags = [...newSystemTags];
      });

    case types.TAG_CHANGE_SUCCESS:
      return produce(state, (draft) => {
        const { tagType, value, index } = action;

        const newTags = [...state.systemTags];

        newTags[index] = {
          ...newTags[index],
          [tagType]: value,
        };
        draft.systemTags = [...newTags];
      });
    case types.GET_SUPPORTED_SYSTEM_SUCCESS:
      return produce(state, (draft) => {
        draft.supportedSystemList = action.data;
      });
    case types.GET_SUPPORTED_SYSTEM_FAILED:
      return produce(state, (draft) => {
        draft.supportedSystemList = [];
      });
    case types.GET_CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.categoriesList = action.data;
      });
    case types.GET_CATEGORIES_FAILED:
      return produce(state, (draft) => {
        draft.categoriesList = [];
      });
    case types.GET_SUBCATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.subCategoriesList = action.data;
      });
    case types.GET_SUBCATEGORIES_FAILED:
      return produce(state, (draft) => {
        draft.subCategoriesList = [];
      });
    case types.SET_SELECTED_CATEGORY:
      return produce(state, (draft) => {
        draft.selectedCategory = action.selectedCategpry;
      });
    case types.SET_SELECTED_SUBCATEGORY:
      return produce(state, (draft) => {
        draft.selectedSubcategory = action.subcategory;
      });
    case types.SET_CATEGORY_TYPE:
      return produce(state, (draft) => {
        draft.categoryType = action.value;
      });
    case types.SET_SELECTED_INPUT_CATEGORY:
      return produce(state, (draft) => {
        draft.selectedInputCategory = action.selectedCategpry;
      });
    case types.SET_SUBCATEGORY_TYPE:
      return produce(state, (draft) => {
        draft.subCategoryType = action.value;
      });
    case types.SET_SELECTED_INPUT_SUBCATEGORY:
      return produce(state, (draft) => {
        draft.selectedInputSubCategory = action.subCategory;
      });
    case types.GET_FILES_BY_CATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.categoryFiles = action.data;
      });
    case types.GET_FILES_BY_CATEGORIES_FAILED:
      return produce(state, (draft) => {
        draft.categoryFiles = [];
      });
    case types.UPLOAD_FILES_SUCCESS:
      return produce(state, (draft) => {
        draft.uploadedFileUrls = action.data;
      });
    case types.UPLOAD_FILES_FAILED:
      return produce(state, (draft) => {
        draft.uploadedFileUrls = [];
      });
    case types.RESET_SYSTEM_INFO:
      return produce(state, (draft) => {
        draft.systemTags = defaultSystemTags;
        draft.systemName = "";
        draft.systemType = "";
        draft.systemEnv = [];
        draft.supportedSystemList = [];
        draft.categoriesList = [];
        draft.subCategoriesList = [];
        draft.selectedCategory = [];
        draft.selectedSubcategory = [];
        draft.selectedInputCategory = [];
        draft.categoryFiles = [];
        draft.uploadedFileUrls = [];
        draft.customAnalysis = "";
      });
    default:
      return state;
  }
}
