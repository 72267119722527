import axios from "axios";
import * as actionTypes from "../actionTypes";

const  REACT_APP_API_ENDPOINT  = "http://api.database-services.com:8080";

export const setLoading = (value) => {
  return async (dispatch) => {
    dispatch(setLoadingSuccess(value));
  };
};

export const setLoadingSuccess = (res) => {
  return { type: actionTypes.SET_LOADING, res };
};

export const loginUser = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_API_ENDPOINT}/auth/login`, data)
      .then(async (result) => {
        await dispatch(loginUserSuccess(result.data));
        localStorage.setItem("auth", true);
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(loginUserFailed(error));
        throw new Error(error);
      });
  };
};

export const loginUserSuccess = (currentUser) => {
  return { type: actionTypes.LOGIN_USER_SUCCESS, currentUser };
};

export const loginUserFailed = (error) => {
  return { type: actionTypes.LOGIN_USER_FAILED, error };
};

export const registerUser = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_API_ENDPOINT}/auth/signup`, data)
      .then(async (result) => {
        await dispatch(registerUserSuccess(result.data));
        localStorage.setItem("auth", true);
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(registerUserFailed(error));
        throw new Error(error);
      });
  };
};

export const googleLogin = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_API_ENDPOINT}/auth/google-login`, data)
      .then(async (result) => {
        await dispatch(registerUserSuccess(result.data));
        localStorage.setItem("auth", true);
      })
      .catch(async (err) => {
        const error =
          err.response && err.response.data && err.response.data.message;
        await dispatch(registerUserFailed(error));
        throw new Error(error);
      });
  };
};

export const registerUserSuccess = (currentUser) => {
  return { type: actionTypes.REGISTER_USER_SUCCESS, currentUser };
};

export const registerUserFailed = (error) => {
  return { type: actionTypes.REGISTER_USER_FAILED, error };
};

export const logoutUser = () => {
  localStorage.removeItem("auth");
  return { type: actionTypes.LOGOUT_USER_SUCCESS };
};
