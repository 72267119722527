import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { NotificationManager } from "react-notifications";
import { useNavigate } from "react-router-dom";
import analyticsLogo from "../assets/analytics-logo.png";
import InputField from "../components/InputField";
import { registerUser } from "../redux/actions/authActions";

const Register = ({ signinNavigationHandler }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fullName, setFullName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const signupHandler = async () => {
    const data = {
      fullName,
      email,
      password,
      company: companyName,
    };

    try {
      await dispatch(registerUser(data));
      navigate("/dashboard");
      NotificationManager.success("Welcome to Data Analytics!");
    } catch (error) {
      NotificationManager.error(error.message);
    }
  };
  
  return (
    <section class="bg-gray-50 dark:bg-gray-900 w-full">
      <div class="flex flex-col px-6 mt-8 mx-auto lg:py-0">
        <div class="flex flex-row justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img
            src={analyticsLogo}
            class="mr-3 h-6 sm:h-9"
            alt="Flowbite Logo"
          />
          <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Data Anaytics
          </span>
        </div>
        <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Create account
            </h1>
            <div class="space-y-2 md:space-y-6">
              <div>
                <label
                  for="fullName"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  FullName
                </label>
                <InputField
                  type="text"
                  name="fullName"
                  required={true}
                  value={fullName}
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  for="email"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <InputField
                  type="email"
                  name="email"
                  required={true}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div>
                <label
                  for="password"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Password
                </label>
                <InputField
                  type="password"
                  name="password"
                  required={true}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </div>

              <div>
                <label
                  for="company"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Company Name
                </label>
                <InputField
                  type="text"
                  name="company"
                  value={companyName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                />
              </div>

              <button
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                onClick={signupHandler}
              >
                Create an account
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Already have an account?{" "}
                <span
                  class="font-medium text-primary-600 hover:underline dark:text-primary-500 cursor-pointer"
                  onClick={signinNavigationHandler}
                >
                  Login here
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
