import React, { useEffect } from "react";
import InputField from "../components/InputField";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import Select from "react-select";
import {
  addSystemTag,
  getCategories,
  getSupportedSystem,
  handleTagChange,
  removeSystemTag,
  setSystemInfoValue,
} from "../redux/actions/userSystemActions";
import { useDispatch, useSelector } from "react-redux";
import { systemEnvOptions } from "../utils";

const SystemInfo = ({ handleNextStep }) => {
  const dispatch = useDispatch();

  const systemTags = useSelector((state) => state.userSystem.systemTags);
  const systemName = useSelector((state) => state.userSystem.systemName);
  const systemType = useSelector((state) => state.userSystem.systemType);
  const systemEnv = useSelector((state) => state.userSystem.systemEnv);
  const supportedSystemList = useSelector(
    (state) => state.userSystem.supportedSystemList
  );


  useEffect(() => {
    dispatch(getSupportedSystem());
  }, [dispatch]);

  const handleInputChange = (key, value) => {
    dispatch(setSystemInfoValue(key, value));
  };

  const nextHandler = () => {
    dispatch(getCategories(systemType.value));
    handleNextStep();
  };
  const isBtnDisabled =
    !systemName ||
    !systemType ||
    !systemEnv ||
    !systemTags[0]["key"] ||
    !systemTags[0]["value"];

  const supportedSystemOptions =
    supportedSystemList && supportedSystemList.length > 0
      ? supportedSystemList.map((option) => {
          return {
            label: option.displayName,
            value: option.name,
          };
        })
      : [];

  return (
    <div className="mx-4 my-20">
      <div class="mb-2 w-full">
        <div className="flex flex-row my-8 w-full">
          <label
            for="system_type"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-10"
          >
            System type :
          </label>
          <div className="w-1/3">
            <Select
              onChange={(value) => {
                handleInputChange("systemType", value);
              }}
              value={systemType}
              options={supportedSystemOptions}
            />
          </div>{" "}
        </div>
        <div className="flex flex-row mb-8">
          <label
            for="system_name"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-9"
          >
            System name :
          </label>
          <div className="w-1/3">
            <InputField
              type="text"
              onChange={(e) => {
                handleInputChange("systemName", e.target.value);
              }}
              value={systemName}
            />
          </div>
        </div>
        <div className="flex flex-row mb-8">
          <label
            for="system_env"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-12"
          >
            System env :
          </label>
          <div className="w-1/3">
            <Select
              onChange={(value) => {
                handleInputChange("systemEnv", value);
              }}
              value={systemEnv}
              options={systemEnvOptions}
              name="systemEnv"
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  boxShadow:
                    state.isFocused || state.isActive
                      ? "none"
                      : provided.boxShadow,
                }),
              }}
            />
          </div>
        </div>
        <div className="flex flex-row">
          <label
            for="first_name"
            class="block mb-2 text-sm font-medium text-gray-900 flex items-center mr-14"
          >
            System tags :
          </label>
          <div className="flex flex-col w-full">
            {systemTags.map((tag, index) => (
              <div className="flex flex-row w-2/5 mb-4 gap-4">
                <InputField
                  placeholder="key"
                  className="mr-2"
                  onChange={(e) =>
                    dispatch(handleTagChange("key", e.target.value, index))
                  }
                  value={tag.key}
                />
                <InputField
                  placeholder="value"
                  onChange={(e) =>
                    dispatch(handleTagChange("value", e.target.value, index))
                  }
                  value={tag.value}
                />
                {index === 0 ? (
                  <div
                    className="w-24 cursor-pointer"
                    onClick={() => dispatch(addSystemTag())}
                  >
                    <AiFillPlusCircle className=" text-3xl mx-3 my-2" />
                  </div>
                ) : (
                  <div
                    className="w-24 cursor-pointer"
                    onClick={() => dispatch(removeSystemTag(index))}
                  >
                    <AiFillMinusCircle className=" text-3xl mx-3 my-2" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <button
          class={`bg-blue-700 text-white font-bold py-2 px-4 rounded h-12 w-24 float-right ${
            isBtnDisabled && "opacity-50 cursor-not-allowed bg-blue-500"
          }`}
          onClick={nextHandler}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SystemInfo;
